
<template>
	<div>
		<PlatformHeader v-show="route.name != 'account'" />
		<slot id="nuxt--content" />

		<footer v-if="route.name != 'account'">
			<div class="grid">
				<IconLogo />
				<a href="https://ravespace.io" class="g">© 2023 by RAVE SPACE</a>
				<div class="so">
					<a href="https://twitter.com/ravespaceio" target="_blank"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_722_103)">
								<path d="M16.375 3.6155C15.7955 3.87275 15.1728 4.046 14.5191 4.12409C15.1865 3.72444 15.6991 3.09116 15.9399 2.33647C15.3158 2.70659 14.6241 2.97566 13.8878 3.12069C13.2992 2.49266 12.4585 2.10022 11.5292 2.10022C9.44303 2.10022 7.91003 4.04666 8.38122 6.06725C5.6965 5.93272 3.31562 4.64647 1.72159 2.6915C0.875031 4.14378 1.28256 6.04363 2.72106 7.00569C2.19212 6.98863 1.69337 6.8436 1.25828 6.60144C1.22284 8.09834 2.29581 9.49878 3.84981 9.8105C3.39503 9.93388 2.89694 9.96275 2.39031 9.86563C2.80113 11.1493 3.99419 12.0831 5.40906 12.1093C4.05063 13.1744 2.33913 13.6502 0.625 13.4481C2.05497 14.3649 3.754 14.8997 5.57837 14.8997C11.5778 14.8997 14.9673 9.83281 14.7626 5.28828C15.3939 4.83219 15.9419 4.26322 16.375 3.6155Z" fill="#272931" />
							</g>
							<defs>
								<clipPath id="clip0_722_103">
									<rect width="15.75" height="15.75" fill="white" transform="translate(0.625 0.625)" />
								</clipPath>
							</defs>
						</svg> </a>
					<a href="https://www.linkedin.com/company/ravespaceio" target="_blank"><svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4.98 3.5C4.98 4.881 3.87 6 2.5 6C1.13 6 0.02 4.881 0.02 3.5C0.02 2.12 1.13 1 2.5 1C3.87 1 4.98 2.12 4.98 3.5ZM5 8H0V24H5V8ZM12.982 8H8.014V24H12.983V15.601C12.983 10.931 19.012 10.549 19.012 15.601V24H24V13.869C24 5.989 15.078 6.276 12.982 10.155V8Z" fill="#272931" />
						</svg> </a>
					<a href="https://www.instagram.com/ravespace.io/" target="_blank"><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_722_100)">
								<path d="M12 2.163C15.204 2.163 15.584 2.175 16.85 2.233C20.102 2.381 21.621 3.924 21.769 7.152C21.827 8.417 21.838 8.797 21.838 12.001C21.838 15.206 21.826 15.585 21.769 16.85C21.62 20.075 20.105 21.621 16.85 21.769C15.584 21.827 15.206 21.839 12 21.839C8.796 21.839 8.416 21.827 7.151 21.769C3.891 21.62 2.38 20.07 2.232 16.849C2.174 15.584 2.162 15.205 2.162 12C2.162 8.796 2.175 8.417 2.232 7.151C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163ZM12 0C8.741 0 8.333 0.014 7.053 0.072C2.695 0.272 0.273 2.69 0.073 7.052C0.014 8.333 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.272 21.306 2.69 23.728 7.052 23.928C8.333 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C21.302 23.728 23.73 21.31 23.927 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.333 23.928 7.053C23.732 2.699 21.311 0.273 16.949 0.073C15.668 0.014 15.259 0 12 0V0ZM12 5.838C8.597 5.838 5.838 8.597 5.838 12C5.838 15.403 8.597 18.163 12 18.163C15.403 18.163 18.162 15.404 18.162 12C18.162 8.597 15.403 5.838 12 5.838ZM12 16C9.791 16 8 14.21 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.21 14.209 16 12 16ZM18.406 4.155C17.61 4.155 16.965 4.8 16.965 5.595C16.965 6.39 17.61 7.035 18.406 7.035C19.201 7.035 19.845 6.39 19.845 5.595C19.845 4.8 19.201 4.155 18.406 4.155Z" fill="#272931" />
							</g>
							<defs>
								<clipPath id="clip0_722_100">
									<rect width="24" height="24" fill="white" />
								</clipPath>
							</defs>
						</svg> </a>
				</div>
				<div class="list">
					<a href="/imprint">Imprint</a>
					<a href="/privacy-policy">Privacy Policy</a>
				</div>
			</div>
		</footer>

	</div>
</template>

<script lang="ts">


export default {
	data() {
		return {
			route: useRoute()
		}
	},
	watch: {
		$route: {
			// dont use immeadeate bc document not defined on ssr server
			handler(to, from) {
				this.checkURL()
			},
		},
	},
	mounted() {
		this.checkURL()
	},
	methods: {
		checkURL() {
			document.body.classList.forEach((c) => {
				if (c.startsWith("body--")) document.body.classList.remove(c)
			})
			const route = this.$route.fullPath.split('/')[1];
			document.body.classList.add("body--" + route)
		}
	},
}
</script>


<style lang="scss">
@import "assets/css/platform.scss";

#nuxt--content {
	min-height: 100vh;
}

.body--space,
.body--artist {
	background: $main;
}
</style>
